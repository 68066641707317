<div class="youtube-wrapper">
  <iframe
    class="youtube-iframe"
    [src]="YT_URL + '&rel=0' | safeUrl"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  ></iframe>
</div>
