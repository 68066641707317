import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { END_POINT } from '../const/end-point.const';
interface ApiResponse {
  code: string;
  message: string;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class ContactFormService {
  constructor(private _http: HttpClient) {}

  submitContactForm(submitFrom: any, formType: string = '') {
    return this._http.post<ApiResponse>(
      `${END_POINT.BE_URL}/api/v1/contact-us?formType=${formType}`,
      submitFrom,
      httpOptions
    );
  }
}
