<div class="row">
  <div *ngFor="let data of WizardTextBoxs; let i = index" class="col-lg-6">
    <div class="content-container d-flex flex-column flex-gap-24px">
      <div
        class="content-container_img"
        [style.backgroundImage]="'url(assets/imgs/' + data.image + ')'"
      ></div>
      <button (click)="openPopUpForm()" class="button-primary">ASK IRIS</button>
      <h2 class="content-container_header text-center">{{ data.title }}</h2>
      <p class="content-container_header content-container_subHeader text-center fs-22px">
        {{ data.header }}
      </p>
      <div class="wraper d-flex flex-column flex-gap-24px content-container-box">
        <p class="content-description" [innerHTML]="data.description"></p>
        <ul class="wizard-list">
          <li class="fs-16px" *ngFor="let item of data.list">
            {{ item }}
          </li>
        </ul>
        <button (click)="openModal(i + 1)" class="content-container_btn">Learn more</button>
      </div>
    </div>
  </div>
</div>
