import { Component, OnInit, TemplateRef } from '@angular/core';
import { CsvDataService } from 'src/app/shared/services/csv-data.service';
import { Member } from 'src/app/models/member.model';
import { Papa } from 'ngx-papaparse';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  data: Member[] = [];
  teamAntrix: Member[] = [
    {
      name: 'Bill Kurani',
      imgPath: 'member-5.png',
      title: 'Founder - Board of Directors',
      description:
        'Bill has extensive experience in regulatory affairs, clinical affairs, and quality assurance of medical devices. Bill is applying the learnings from medical device companies and helping customers working on AI products and services implement AI ISO standards, regulations, and guidance.\n' +
        'Bill skilled in securing GLOBAL, FDA, EU IVDR, MDR, CLIA, CAP, NYSDOH AABB and local regulatory clearances on time allowing companies to market Medical Devices, IVD, LDT, Digital Health Applications such as Software as Medical Device, software containing AI, ML, and NPL, mobile medical app rapidly and generate revenues.\n' +
        'Bill has an excellent background in product design, development, clinical trials, verification, validation, process validation & launch. He has performed hazard and risk analysis of the product and manufacturing process helping companies deliver safe and effective products. Bill has set up manufacturing facilities, CLIA CAP labs and implemented the establishment, maintenance and performance of the company’s quality system based on ISO 13485, FDA QSR, ISO 9001, 210, ISO27001, MDSAP, IVDR, MDR, CAPA’s, and hosted several FDA unannounced inspections.'
    },
    {
      name: 'Hemal Kurani',
      imgPath: 'member-9.png',
      title: 'Co-Founder',
      description:
        'Hemal Kurani is currently pursuing her Bachelor in Bioengineering + Business at UC Berkeley Management, Entrepreneurship, & Technology (M.E.T.) program\n' +
        'Hemal Kurani is working as a student intern with Carbon Sustain which is designed to empower business to take charge of their sustainability efforts. Hemal has also worked as student strategy consultant for Blue Origin and MongoDB. She has also worked as Clinical Laboratory Associate Intern with Vibrant America. As a intern she assisted in running patient diagnostic tests and research assays in Chemistry, Hematology, Immunology, and Genomics.\n' +
        'My personal experiences in various companies as led me to believe that AI compliance is a necessity for every industry area. I decided to start ISOAI.AI and LDTtoIVD so that AI compliance activity will immensely help users and consumers. It will also free up the companies to work and focus on their products.\n' +
        'Hemal Kurani has several patents under her name.  Hemal believes a strong patent allows consumers to have access to innovative products. She believes AI related patents for automation and personalization of tasks will also be very beneficial to consumers as well as industries since they have got rigorous scrutiny  by the United States Patent Office reviewers'
    },
    {
      name: 'Antrix, Inc',
      imgPath: 'member-10.png',
      title: 'Investor',
      description:
        'Antrix Inc. is investor in ISOAI.AI, and LDTtoIVD.com a company that offers an AI-powered platform to streamline achieving compliance with various ISO standards relevant to AI development and deployment. The isoai AIRA and LDTtoIVD IRIS AI agenst, assists companies in establishing and maintaining a quality management system, managing risks, and ensuring data integrity throughout the AI product lifecycle. It caters to a broad range of industries, including healthcare, finance, and aerospace, with a specialization in medical device machine learning models.'
    }
  ];

  constructor(
    private _csvService: CsvDataService,
    private papa: Papa,
    private modal: NzModalService
  ) {}

  ngOnInit(): void {
    const csvFileUrl = '../../../assets/csv/member_data.csv';
    this._csvService.getCsvData(csvFileUrl).subscribe((csvData) => {
      this.papa.parse(csvData, {
        header: true,
        complete: (result) => {
          this.data = result.data
            .filter((row: Member) => row.name && row.title)
            .map((row: Member) => ({
              imgPath: row.imgPath,
              name: row.name,
              title: row.title,
              description: row.description
            }));
        }
      });
    });
  }

  openModal(index: number, title: TemplateRef<any>, content: TemplateRef<any>) {
    this.modal.create({
      nzTitle: title,
      nzContent: content,
      nzFooter: null
    });
  }
}
