<app-youtube-iframe YT_URL="{{ SERVICE_YT_URL }}"></app-youtube-iframe>

<div class="container">
  <div class="d-flex flex-column flex-gap-24px align-items-center mb-32px pt-48px">
    <h1 class="title m-0">OUR SERVICES</h1>
    <form class="search-container d-flex justify-content-center w-xl-50" (ngSubmit)="onSubmit()">
      <div class="search-box">
        <img class="search-box_icon" src="assets/icons/icon-search.svg" alt="" />
        <input
          [(ngModel)]="searchQuery"
          (ngModelChange)="searchSubject.next($event)"
          name="searchQuery"
          class="search-box_input"
          placeholder="Search our services"
          type="text"
        />
      </div>
      <button type="submit" class="search-container_btn">Search</button>
    </form>
  </div>
  <app-card
    SubText="*FREE Consultation for half an hour"
    [ProductsData]="filteredProducts"
    FirstBtnText="Book Now"
  ></app-card>
</div>
