import { Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PopupFormComponent } from '../components/popup-form/popup-form.component';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private selectedProServiceNameSource = new BehaviorSubject<string>(''); // to save the selected pro service name
  selectedProServiceName = this.selectedProServiceNameSource.asObservable();

  private selectedPartNumberSource = new BehaviorSubject<string>(''); // to save the selected part number
  selectedPartNumber = this.selectedPartNumberSource.asObservable();

  isFirstModalOpen = new BehaviorSubject<boolean>(false);
  isSecondModalOpen = new BehaviorSubject<boolean>(false);

  constructor(private modal: NzModalService) {}

  updateSelectedProServices(proService: { name: string; partNumber: string }): void {
    this.selectedProServiceNameSource.next(proService.name);
    this.selectedPartNumberSource.next(proService.partNumber);
  }

  openModalForm(viewcontainerRef: ViewContainerRef) {
    const modal = this.modal.create({
      nzTitle: undefined,
      nzContent: PopupFormComponent,
      nzViewContainerRef: viewcontainerRef,
      nzFooter: null,
      nzClassName: 'modal-form',
      nzOnOk: () => new Promise((resolve) => setTimeout(resolve, 1000))
    });
    const instance = modal.getContentComponent();
  }
}
