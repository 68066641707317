import { Component, EventEmitter, Output, ViewContainerRef } from '@angular/core';
import { SharedDataService } from '../../../shared/services/shared-data.service';
interface IWizardTextBox {
  title: string;
  image: string;
  header: string;
  description: string;
  list: string[];
}
@Component({
  selector: 'app-wizard-text-box',
  templateUrl: './wizard-text-box.component.html',
  styleUrls: ['./wizard-text-box.component.scss']
})
export class WizardTextBoxComponent {
  WizardTextBoxs: IWizardTextBox[] = [
    {
      title: 'For US LDT Labs',
      image: 'US_LDT_Labs.JPG',
      header: 'Get Started with AI Powered IRIS Wizard',
      description:
        'Our unique program allows your lab to be compliant with FDA IVD regulation for your in-house LDT tests. <strong>US LDT Labs Program</strong> navigates labs to full compliance for US FDA requirements for Lab Developed Test.',
      list: [
        'Cost-effective access to US FDA templates, and rules-based automation for quickly creating the set of FDA documentation.',
        'Access to the required FDA documentation template for components like reagents, microarray, custom oligos, instruments, software etc. in your Lab Developed Assay Test.',
        'Access to application support personnel to guide you through your entire process.'
      ]
    },
    {
      title: 'For EU LDT Labs',
      image: 'EU_LDT_Labs.JPG',
      header: 'Get Started with AI Powered IRIS Wizard',
      description:
        'Our unique program allows your lab to be compliant with EU IVDR 2017/746 regulation for your in-house tests. <strong>EU LDT Labs Program</strong> navigates labs to full compliance for EU IVDR requirements for Lab Developed Test.',
      list: [
        'Cost-effective access to US IVDR templates, and rules-based automation for quickly creating the set of EU IVDR / 2017 documentation.',
        'Access to the required IVDR documentation template for components like reagents, microarray, custom oligos, instruments, software etc. in your Lab Developed Assay Test.',
        'Access to application support personnel to guide you through your entire process.'
      ]
    }
  ];
  constructor(
    private _SharedDataService: SharedDataService,
    private viewContainerRef: ViewContainerRef
  ) {}
  openModal(index: number): void {
    switch (index) {
      case 1:
        this._SharedDataService.isFirstModalOpen.next(true);
        break;
      case 2:
        this._SharedDataService.isSecondModalOpen.next(true);
        break;
    }
  }

  openPopUpForm() {
    this._SharedDataService.openModalForm(this.viewContainerRef);
  }
}
