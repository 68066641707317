<div data-aos="fade-up" class="slider-wraper" id="clients">
  <h2 class="text-center silder-header m-8px">OUR CLIENTS</h2>
  <h2 class="text-center silder-header">WORK EXPERIENCE</h2>
  <div class="slider p-24px">
    <div class="silde-track_right">
      <img src="assets/imgs/1_abbott.jpg" alt="" />
      <img src="assets/imgs/2_affymetrix.jpg" alt="" />
      <img src="assets/imgs/3_c8medisensors.jpg" alt="" />
      <img src="assets/imgs/4_google.jpg" alt="" />
      <img src="assets/imgs/5_glooko.jpg" alt="" />
      <img src="assets/imgs/7_ligand.jpg" alt="" />
      <img src="assets/imgs/8_mdspharma.jpg" alt="" />
      <img src="assets/imgs/9_natera.jpg" alt="" />
      <img src="assets/imgs/10_nicolet.jpg" alt="" />
    </div>
    <div class="silde-track_right">
      <img src="assets/imgs/1_abbott.jpg" alt="" />
      <img src="assets/imgs/2_affymetrix.jpg" alt="" />
      <img src="assets/imgs/3_c8medisensors.jpg" alt="" />
      <img src="assets/imgs/4_google.jpg" alt="" />
      <img src="assets/imgs/5_glooko.jpg" alt="" />
      <img src="assets/imgs/7_ligand.jpg" alt="" />
      <img src="assets/imgs/8_mdspharma.jpg" alt="" />
      <img src="assets/imgs/9_natera.jpg" alt="" />
      <img src="assets/imgs/10_nicolet.jpg" alt="" />
    </div>
  </div>

  <hr class="hr" />

  <div class="slider p-24px">
    <div class="silde-track_left">
      <img src="assets/imgs/12_oratech.jpg" alt="" />
      <img src="assets/imgs/14_stryker.jpg" alt="" />
      <img src="assets/imgs/15_spectra.jpg" alt="" />
      <img src="assets/imgs/16_thermofisher.jpg" alt="" />
      <img src="assets/imgs/17_vibrantamerica.jpg" alt="" />
      <img src="assets/imgs/18_ucsc.jpg" alt="" />
      <img src="assets/imgs/20_vibrantwellness.jpg" alt="" />
    </div>
    <div class="silde-track_left">
      <img src="assets/imgs/12_oratech.jpg" alt="" />
      <img src="assets/imgs/14_stryker.jpg" alt="" />
      <img src="assets/imgs/15_spectra.jpg" alt="" />
      <img src="assets/imgs/16_thermofisher.jpg" alt="" />
      <img src="assets/imgs/17_vibrantamerica.jpg" alt="" />
      <img src="assets/imgs/18_ucsc.jpg" alt="" />
      <img src="assets/imgs/20_vibrantwellness.jpg" alt="" />
    </div>
  </div>

  <div class="image-list mb-8px p-14px">
    <img src="assets/imgs/1_abbott.jpg" alt="" />
    <img src="assets/imgs/2_affymetrix.jpg" alt="" />
    <img src="assets/imgs/3_c8medisensors.jpg" alt="" />
    <img src="assets/imgs/4_google.jpg" alt="" />
    <img src="assets/imgs/5_glooko.jpg" alt="" />
    <img src="assets/imgs/7_ligand.jpg" alt="" />
    <img src="assets/imgs/8_mdspharma.jpg" alt="" />
    <img src="assets/imgs/9_natera.jpg" alt="" />
    <img src="assets/imgs/10_nicolet.jpg" alt="" />
    <img src="assets/imgs/12_oratech.jpg" alt="" />
    <img src="assets/imgs/14_stryker.jpg" alt="" />
    <img src="assets/imgs/15_spectra.jpg" alt="" />
    <img src="assets/imgs/16_thermofisher.jpg" alt="" />
    <img src="assets/imgs/17_vibrantamerica.jpg" alt="" />
    <img src="assets/imgs/18_ucsc.jpg" alt="" />
    <img src="assets/imgs/20_vibrantwellness.jpg" alt="" />
  </div>
</div>
