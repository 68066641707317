<div class="slider-wraper">
  <div class="container">
    <div class="slider-container position-relative">
      <h2 class="slider-header fs-34px text-center">TESTIMONIALS</h2>
      <swiper-container
        navigation-next-el=".custom-next-button"
        navigation-prev-el=".custom-prev-button"
        class="mySwiper"
        #swiper
      >
        <swiper-slide class="swiper-slide">
          <div class="d-flex flex-column w-50 flex-gap-48px align-items-center">
            <p class="text-center fs-20px slider-text">
              “LDTtoIVD is a highly professional and dedicated consulting firm that setup QMS, train
              and hosted ISO 13485, US FDA 820, ISO 15189 CLIA, CAP, and NYSDOH audits. They
              documented the complete agile product development lifecycle, assisted in clinical
              trials of the products and ensured LDT products received the regulatory clearances.
              This allowed us to focus on our core business of patient laboratory testing.”
            </p>
            <p class="slider-subText">Medical Director</p>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="d-flex flex-column w-50 flex-gap-48px align-items-center">
            <p class="text-center fs-20px slider-text">
              “LDTtoIVD provided us with expert regulatory affairs and quality assurance consulting
              services. They helped us set up our US FDA CFR 820, ISO 13485, ISO 15189, CLIA, CAP,
              and NYSDOH Quality Management System and get certified. We are extremely happy with
              their services and would highly recommend them to anyone looking for similar
              services.”
            </p>
            <p class="slider-subText">Quality and Operations Manager</p>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <div class="d-flex flex-column w-50 flex-gap-48px align-items-center">
            <p class="text-center fs-20px slider-text">
              “We purchased the LDTtoIVD QMS products and services for ISO 13485 for medical
              devices, ISO 22716 for cosmetics, ISO 22000 for food safety. They helped us document
              the regulatory strategy and risk management of our products. We are very happy with
              their products and services and would highly recommend them to anyone looking for
              similar services.”
            </p>
            <p class="slider-subText">Regulatory Affairs Manager</p>
          </div>
        </swiper-slide>
      </swiper-container>
      <button class="custom-next-button">
        <img src="assets/icons/icon-chevron-right.svg" alt="" />
      </button>
      <div class="custom-prev-button">
        <img src="assets/icons/icon-chevron-left.svg" alt="" />
      </div>
    </div>
  </div>
</div>
