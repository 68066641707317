<app-contact-form></app-contact-form>

<div class="footer-wrapper">
  <div class="container">
    <div class="footer d-flex justify-content-between mb-32px">
      <div class="d-flex flex-column flex-gap-32px">
        <div class="p-5px">
          <a class="" routerLink="/"
          ><img class="max-w-55px mr-5px" src="assets/imgs/logo-1.png" alt="" />
            <img src="assets/imgs/logo-2.png" alt=""
            /></a>
        </div>
        <div class="contact-info d-flex flex-column flex-gap-12px">
          <p class="text-white">
            <a class="text-white link-btn" href="https://www.LDTtoIVD.ai/">LDTtoIVD.ai</a> - Business Division of Antrix, Inc.
          </p>
          <p class="text-white">
            <a href="http://isoai.ai/" class="text-white link-btn">ISOAI.AI</a> - Business Division of Antrix, Inc.</p>
          <a class="d-block text-white link-btn" href="https://www.antrix.com">https://www.antrix.com</a>
          <div class="d-flex flex-gap-8px text-white">
            <p class="w-50px fw-light">Email</p>
            <p class="fw-medium">support@ldttoivd.ai</p>
          </div>
          <div class="d-flex flex-gap-8px text-white">
            <p class="w-50px fw-light">Phone</p>
            <p class="fw-medium">408 733 1199 - 408 730 5959</p>
          </div>
          <div class="d-flex flex-gap-8px text-white">
            <p class="w-50px fw-light">Office</p>
            <p class="fw-medium w-80">
              Antrix Inc., 880 West Knickerbocker Dr, Sunnyvale, CA 94087
            </p>
          </div>
          <div class="d-flex flex-gap-32px">
            <a class="fw-medium"
            ><a class="text-white" routerLink="faq">FAQ</a></a
            >
            <p class="fw-medium">
              <a class="text-white" routerLink="careers">Careers</a>
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-gap-16px">
        <p class="text-menu">MENU</p>
        <div class="d-flex flex-column flex-gap-12px">
          <div class="d-flex flex-gap-12px">
            <a routerLink="/info/services" class="w-100 fw-medium text-white"
            >Services</a
            >
            <a routerLink="resources" class="w-100 fw-medium text-white"
            >Resources</a
            >
          </div>
          <div class="d-flex flex-gap-12px">
            <a routerLink="/info/products" class="w-100 fw-medium text-white"
            >Products</a
            >
            <a routerLink="about" class="w-100 fw-medium text-white">About</a>
          </div>
          <div class="d-flex flex-gap-12px">
            <a routerLink="pricing" class="w-100 fw-medium text-white"
            >Pricing</a
            >
            <a href="#contact" class="w-100 fw-medium text-white">Contact</a>
          </div>
        </div>

        <hr class="text-white"/>
        <span class="text-small">
  *6-12 month assay migration time based on classification of the product and commitment of resources identified.
        </span>

        <span class="text-small">
  @2024, LDTtoIVD.ai All rights reserved
        </span>
        <span class="text-small">
  @2024, ISOAI.AI All rights reserved
        </span>
        <span class="text-small">
  @2024, ANTRIX. All rights reserved.
        </span>
      </div>
    </div>
    <hr class="text-white"/>
    <div class="signature">
      ©2024 ANTRIX. All rights reserved. Designed by NeoPrototype. Version 0.1.0.
    </div>
  </div>
</div>
