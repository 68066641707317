<div id="contact" class="footer-container">
  <div class="container">
    <div class="d-flex flex-column align-items-center flex-gap-48px">
      <div class="text-center">
        <h2 class="footer-header text-white">CONTACT US</h2>
        <h2 class="footer-header text-white">Book Now</h2>
      </div>
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="contactUs-form w-50">
        <div class="form-container row">
          <div class="form-input col-lg-6 col-md-12 col-sm-12">
            <label class="input-label">First name*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="First name"
                formControlName="firstName"
              />
              <img
                *ngIf="
                  contactForm.get('firstName')?.invalid &&
                  (contactForm.get('firstName')?.dirty || contactForm.get('firstName')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('firstName')?.invalid &&
                (contactForm.get('firstName')?.dirty || contactForm.get('firstName')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('firstName')?.errors?.['required']">
                Please enter your first name
              </p>
              <p *ngIf="contactForm.get('firstName')?.errors?.['pattern']">
                Please enter your first name without special characters and numbers.
              </p>
            </div>
          </div>
          <div class="form-input col-lg-6 col-md-12 col-sm-12">
            <label class="input-label">Last name*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                id="name"
                class="input-text"
                placeholder="Last name"
                formControlName="lastName"
              />
              <img
                *ngIf="
                  contactForm.get('lastName')?.invalid &&
                  (contactForm.get('lastName')?.dirty || contactForm.get('lastName')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('lastName')?.invalid &&
                (contactForm.get('lastName')?.dirty || contactForm.get('lastName')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('lastName')?.errors?.['required']">
                Please enter your last name
              </p>
              <p *ngIf="contactForm.get('lastName')?.errors?.['pattern']">
                Please enter your last name without special characters and numbers.
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Email*</label>
            <div class="input-text_wraper">
              <input type="text" class="input-text" placeholder="Email" formControlName="email" />
              <img
                *ngIf="
                  contactForm.get('email')?.invalid &&
                  (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('email')?.invalid &&
                (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('email')?.errors?.['required']">Please enter your email</p>
              <p *ngIf="contactForm.get('email')?.errors?.['email']">Please enter valid email</p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">Telephone no*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Telephone no"
                formControlName="phone"
              />
              <img
                *ngIf="
                  contactForm.get('phone')?.invalid &&
                  (contactForm.get('phone')?.dirty || contactForm.get('phone')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('phone')?.invalid &&
                (contactForm.get('phone')?.dirty || contactForm.get('phone')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('phone')?.errors?.['required']">
                Please enter your phone number
              </p>
              <p *ngIf="contactForm.get('phone')?.errors?.['pattern']">
                Please enter a valid phone number.
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Company name*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Company name"
                formControlName="company"
              />
              <img
                *ngIf="
                  contactForm.get('company')?.invalid &&
                  (contactForm.get('company')?.dirty || contactForm.get('company')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('company')?.invalid &&
                (contactForm.get('company')?.dirty || contactForm.get('company')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('company')?.errors?.['required']">
                Please enter your company name
              </p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">Job title*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Job title"
                formControlName="jobTitle"
              />
              <img
                *ngIf="
                  contactForm.get('jobTitle')?.invalid &&
                  (contactForm.get('jobTitle')?.dirty || contactForm.get('jobTitle')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('jobTitle')?.invalid &&
                (contactForm.get('jobTitle')?.dirty || contactForm.get('jobTitle')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('jobTitle')?.errors?.['required']">
                Please enter your job title
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Company website*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Company website"
                formControlName="companyWeb"
              />
              <img
                *ngIf="
                  contactForm.get('companyWeb')?.invalid &&
                  (contactForm.get('companyWeb')?.dirty || contactForm.get('companyWeb')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('companyWeb')?.invalid &&
                (contactForm.get('companyWeb')?.dirty || contactForm.get('companyWeb')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('companyWeb')?.errors?.['required']">
                Please enter your company website
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Product and Services*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="prodService"
                (change)="onProductServiceChange($event)"
                [class.select-text--placeholder]="isSelectChange"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select Products or Services
                </option>
                <optgroup class="select-outgroup" label="Services">
                  <option *ngFor="let service of Services" [value]="service.Name">
                    {{ service.Name }}
                  </option>
                </optgroup>
                <optgroup class="select-outgroup" label="Products">
                  <option *ngFor="let product of Products" [value]="product.Name">
                    {{ product.Name }}
                  </option>
                </optgroup>
                <optgroup class="select-outgroup" label="Subscriptions">
                  <option *ngFor="let sub of Subscriptions" [value]="sub.Name">
                    {{ sub.Name }}
                  </option>
                </optgroup>
              </select>
              <img
                *ngIf="
                  contactForm.get('prodService')?.invalid &&
                  (contactForm.get('prodService')?.dirty || contactForm.get('prodService')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('prodService')?.invalid &&
                (contactForm.get('prodService')?.dirty || contactForm.get('prodService')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('companyWeb')?.errors?.['required']">
                Please select Products or Services
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Part No*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text pe-none fs-14px"
                readonly
                placeholder="Please select Products or Services first"
                formControlName="partNo"
              />
            </div>
          </div>
        </div>
        <div *ngIf="!isPricingHidden" class="form-container">
          <div class="form-input">
            <label class="input-label">Pricing*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="pricing"
                (change)="onPricingChange()"
                [class.select-text--placeholder]="isPricingSelectChange"
              >
                <option class="select-text_placeholder" value="" disabled selected>
                  Please select Products or Services first
                </option>
                <option [hidden]="isHidden" *ngFor="let price of Pricings" [value]="price">
                  {{ price }}
                </option>
              </select>
              <img
                *ngIf="
                  contactForm.get('pricing')?.invalid &&
                  (contactForm.get('pricing')?.dirty || contactForm.get('pricing')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('pricing')?.invalid &&
                (contactForm.get('pricing')?.dirty || contactForm.get('pricing')?.touched)
              "
              class="error-text"
            >
              <p>Please select Pricings</p>
            </div>
          </div>
        </div>

        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Message*</label>
            <div class="input-text_wraper">
              <textarea
                type="text"
                class="textarea-text"
                placeholder="Please provide details about your medical device"
                formControlName="message"
              ></textarea>
              <img
                *ngIf="
                  contactForm.get('message')?.invalid &&
                  (contactForm.get('message')?.dirty || contactForm.get('message')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('message')?.invalid &&
                (contactForm.get('message')?.dirty || contactForm.get('message')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('message')?.errors?.['required']">
                Please enter your message
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <re-captcha
            class="m-auto"
            (resolved)="resolved($event)"
            siteKey="6LcOJM8pAAAAALQLMJSecdrrOODiQ95ZzXDaNW8J"
          ></re-captcha>
        </div>
        <button [disabled]="contactForm.invalid || isLoading" class="form-submit_btn">
          Submit
          <div *ngIf="isLoading" class="request-loading">
            <div class="mb-1">
              <img src="assets/icons/icon-loading.svg" alt="" class="request-loading_icon" />
            </div>
          </div>
        </button>
      </form>
      <iframe
        class="w-70"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d792.7921798211627!2d-122.0491009!3d37.3621845!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb68e22dafbe9%3A0x54ebc20c2b42f7d4!2s880%20W%20Knickerbocker%20Dr%2C%20Sunnyvale%2C%20CA%2094087%2C%20USA!5e0!3m2!1sen!2s!4v1709718758445!5m2!1sen!2s"
        height="350"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isConfirmRequestPopupOpened"
  (nzOnCancel)="handleCancel()"
  [nzFooter]="null"
  nzMaskClosable="true"
  nzClassName="request-livedemo-modal"
>
  <ng-container *nzModalContent>
    <div>
      <div class="header">
        <img
          style="max-width: 133px; max-height: 56px"
          class="header-img bg-img"
          src="assets/imgs/logo-1.png"
        />
      </div>
      <div class="content-wrapper w-100">
        <div
          class="d-flex justify-content-between align-items-center flex-column flex-gap-16px w-100"
        >
          <img class="close-icon" src="assets/icons/icon-circle-check-2.svg" />
          <p class="popup-title">
            Thank you for submitting the form. Our team will review your message and get back to you
            shortly.
          </p>
          <p class="popup-title">
            Thank you for considering LDTtoIVD. We look forward to assisting you.
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
