<div class="home-banner">
  <div class="container">
    <div class="home-banner_content d-flex align-items-center flex-column">
      <h3 class="home-banner_header text-center m-0">
        LDT and IVD Assay Governance Services and Products Powered by AI, ML, and NLP
        <span class="text-danger">IRIS</span> AI Agent
      </h3>
      <p class="home-banner_text m-0 fs-16px">
        Lab Developed Test (LDT) to In Vitro Diagnostic (IVD)
      </p>
      <p class="home-banner_text m-0 fs-16px">We Guarantee IVD migration in 6-12 months*</p>
      <a href="#contact" class="button-primary"> Book Now </a>
    </div>
  </div>
</div>
<div class="container" data-aos="fade-up">
  <div class="content-container wizard d-flex flex-column flex-gap-24px">
    <h2 class="content-container_header content-container_subHeader text-center">
      LDT to IVD Assay Governance
      <h2 class="content-container_header content-container_subHeader text-danger d-inline">
        IRIS
      </h2>
      AI Agent
    </h2>
    <div
      class="content-container_img content-container_img--EXPERTISE"
      [style.backgroundImage]="'url(assets/imgs/our_expertise.jpg)'"
    ></div>
    <button (click)="openPopUpForm()" class="button-primary">ASK IRIS</button>
    <div class="fs-24px content-container_header content-container_subHeader text-center">
      Get Started with IRIS AI Agent
    </div>
    <p
      class="content-container_header content-container_subHeader wizard-subheader text-center fs-16px"
    >
      Submit your lab developed test, in vitro diagnostic or research use only test product
      information to our AI Agent
    </p>
    <p
      class="content-container_header content-container_subHeader wizard-subheader text-center fs-16px"
    >
      <strong>Our AI Agent will do all the work and create the following:</strong>
    </p>
    <div class="d-flex justify-content-center">
      <ol style="color: #082a43" class="">
        <li class="content-list_item">
          <strong>Regulatory Strategy Governance</strong> - Documentation for Regulatory Strategy
          Plan, Pre-Sub Submissions, Q-Submissions, Scientific Validity and Literature Review,
          Analytical and Clinical Study Plan, Cost, and Protocols.
        </li>
        <li class="content-list_item">
          <strong>Product Realization Governance</strong> - Documentation for Design controls
          documentation for Medical Device File and Design History File consisting of User
          Requirements, Product Requirements, Risk Management File, Design Verification, Design
          Validation, AI Verification and Validation, Clinical Study Reports, Stability, Product
          Labeling, Manufacturing Process Validation, and Device Master Record.
        </li>
        <li class="content-list_item">
          <strong>Regulatory Submission Governance</strong> - Documentation for 510(k), PMA, EU
          Summary of Technical Documentation (STED), General Safety and Performance Requirements
          (GSPR), and Declaration of Conformity
        </li>
        <li class="content-list_item">
          <strong>Product Post Market Surveillance Governance</strong> - Documentation for Post
          Market Surveillance Plan and Reports, Post Market Clinical Follow-up Plan and Reports,
          Periodic Safety Update Reports.
        </li>
        <li class="content-list_item">
          <strong>Product Intelligence Governance</strong> - Documentation for Regulatory
          Intelligence Report, Clinical Intelligence Report, and PMS Intelligence Report.
        </li>
        <li class="content-list_item">
          <strong>Quality Management System Governance</strong> - Documentation for Quality Manual,
          Quality System Procedure and Work Instructions based on ISO 13485, ISO 15189, ISO 14971,
          ISO 62304, MDSAP, CLIA, CAP, NYSDOH requirements. Training, Person Responsible for
          Regulatory Compliance Agreement, Notified Body Agreement, Authorized Representative
          Agreement, Importer Agreement, Distributor Agreement.
        </li>
      </ol>
    </div>
    <p
      class="content-container_header content-container_subHeader wizard-subheader text-center fs-16px"
    >
      Fast-track your lab-developed test (LDT) migration to in vitro diagnostic (IVD) test and
      approval with the LDTtoIVD AI Agent and AI Assistant. The LDTtoIVD IRIS Agent creates
      regulatory strategy, literature study, scientific study, performance study, analytical study,
      clinical study, stability study, shipping study, product validation, manufacturing validation,
      labeling, GSPR, STED, DOC, 510(k), PMA that meet relevant regulations and standards for:
    </p>
    <div class="d-flex justify-content-center">
      <ol style="color: #082a43" class="">
        <li class="content-list_item">
          Medical Device ISO 13485, FDA QSMR, MDSAP, and EU IVDR 2017/746 Regulations
        </li>
        <li class="content-list_item">
          LDT ISO 15189, CLIA, and CAP Assay Products Migration to US FDA IVD Compliance
          Requirements
        </li>
        <li class="content-list_item">
          LDT ISO 15189, CLIA, and CAP Assay Products Migration to EU IVDR Compliance Requirements
        </li>
      </ol>
    </div>
    <p
      class="content-container_header content-container_subHeader wizard-subheader text-center fs-16px"
    >
      AI Agent analyzes the information you provide and uses machine learning models trained on
      clinical laboratory data from various IVD discipline such as
      <strong>
        chemistry, genetic testing, hematology, immunology, microbiology, molecular diagnostics,
        pathology, toxicology, trace elements and more. The model leverages FDA, EU and ROW
        Regulations, ISO Standards and Guidance's, CLSI Standards, ANSI Standards and other relevant
        regulations.
      </strong>
    </p>
    <div class="d-flex flex-column flex-gap-12px">
      <div>
        <h1 class="wizard-content-description">What is a Laboratory Developed Test (LDT)?</h1>
        <p>
          A Laboratory Developed Test (LDT), also known as an in-house test or a homebrew test,
          refers to a diagnostic test that is developed, validated, and performed by a single
          laboratory for its own use. These tests are typically designed and implemented within a
          specific laboratory to meet the unique needs of that laboratory's patient population.
        </p>
      </div>
      <div>
        <h1 class="wizard-content-description">What is In Vitro Diagnostic (IVD) Test?</h1>
        <p>
          In vitro diagnostics (IVDs) are tests that can detect disease, conditions and infections.
          These tests are approved by US FDA and have gone through EU IVDR 2017/746 CE IVD
          Conformity Assessment.
        </p>
      </div>
    </div>
  </div>
  <app-wizard-text-box></app-wizard-text-box>
  <!--Services And Products banner-->
  <div class="mt-3">
    <div class="row">
      <div *ngFor="let content of Contents" class="col-lg-4 col-md-12">
        <div class="content-container d-flex flex-column flex-gap-24px">
          <h2 class="content-container_header text-center">
            {{ content.header }}
          </h2>
          <div
            class="content-container_img"
            [style.backgroundImage]="'url(assets/imgs/' + content.imgPath + ')'"
          ></div>
          <div class="wraper d-flex flex-column flex-gap-24px content-container-box">
            <p class="text-center content-description">
              {{ content.description }}
            </p>
            <ul *ngIf="content.list && content.list.length > 0" class="content-list p-0 m-0">
              <li *ngFor="let item of content.list; let idx = index" class="content-list_item">
                {{ idx + 1 }}. {{ item }}
              </li>
            </ul>
          </div>
          <button
            *ngIf="content.link !== ''"
            routerLink="{{ content.link }}"
            class="content-container_btn"
          >
            Learn more
          </button>
          <button
            *ngIf="content.link === ''"
            (click)="scrollToClients('clients')"
            class="content-container_btn"
          >
            Learn more
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<hr />
<div data-aos="fade-up" data-aos-duration="2000" class="container">
  <!--Advantage banner-->
  <div class="advantage-banner">
    <h2 class="advantage-banner_header">YOUR SAVINGS</h2>
    <div
      class="advantage-banner_img mb-12px"
      [style.backgroundImage]="'url(assets/imgs/your_savings.jpg)'"
    ></div>
    <p class="advantage-banner_description mb-24px">
      Subscribe to regulatory, clinical, PMS intelligence reports work. Get customized reports for
      your company products every month delivered to you via email or deposited into shared site.
      Powered by Generative AI, ML, and NPL - Data mining the regulatory agencies product
      classification, intended use, predicate devices, clinical studies, and PMS databases
      accelerate product development and save millions of dollars by avoiding delayed launches,
      recalls, adverse events, and quality issues. Recognize revenue faster. Saving depends on
      classification and number of products.
    </p>
    <a href="#contact" class="advantage-banner_description button-primary">Subscribe Now</a>
    <div class="d-flex flex-wrap flex-gap-32px w-100">
      <div *ngFor="let advan of Advantages" class="advantage-item">
        <div class="advantage-banner_card text-center text-white">
          <img class="mb-20px" src="assets/icons/{{ advan.icon }}" alt="" />
          <h4 class="advantage-banner_card-header mb-12px">
            {{ advan.header }}
          </h4>
          <p class="fs-20px lh-30px">Save</p>
          <div class="price">
            >
            <span class="dollar fs-16px mb-15px">$</span>
            <span class="ammount">{{ advan.ammount }} Million</span>
          </div>
          <p class="fs-12px lh-30px">{{ advan.subText }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- About us banner -->
<div class="aboutus-banner mb-48px">
  <div class="container">
    <div class="d-flex flex-column align-items-center flex-gap-24px">
      <h2 class="m-0 aboutus-heading">ABOUT US</h2>
      <p class="m-0 aboutus-text">
        LDTtoIVD provides expert regulatory affairs, clinical affairs, and quality assurance
        consulting services and sells products to the IVD, LDT, Life Science, Biotech Industries
        worldwide powered by Artificial Intelligence (AI), Machine Learning (ML), and Natural
        Language Processing (NLP). We believe in delivering quality and timely services to our
        clients to ensure their success in the market. Our team of experts have combined 100 plus
        years of experience in the field and is dedicated to providing the best services to our
        clients. Contact us today to learn more about how we can help you achieve success in the
        market.
      </p>
      <button routerLink="about" class="aboutus-btn">Learn More</button>
    </div>
  </div>
</div>

<!-- Auto Slide Logo -->
<app-auto-slide-logo></app-auto-slide-logo>

<!-- Text Slide -->
<app-text-slider></app-text-slider>

<!-- OPEN MODAL -->
<div [hidden]="!isOpenFirstModal" class="details-modal-overlay" (click)="closeModal(1)">
  <div (click)="$event.stopPropagation()" class="details-modal">
    <div (click)="closeModal(1)" class="details-modal-close">
      <img class="z-1" src="assets/icons/icon-x-close.svg" alt="" />
    </div>
    <div class="details-modal-header">
      <h1 class="details-modal-header_name">For US LDT Labs</h1>
      <span class="details-modal-header_title">Get Started with IRIS AI Agent</span>
    </div>
    <div class="details-modal-content">
      <!--      <div class="details-modal-content_img"-->
      <!--           [style.backgroundImage]="'url(assets/imgs/our_expertise.jpg)'"></div>-->
      <div class="">
        <p class="details-modal-content_description">
          Our unique program allows your lab to be compliant with FDA IVD regulation for your
          in-house LDT tests. <strong>US LDT Labs Program</strong> navigates labs to full compliance
          for US FDA requirements for Lab Developed Test.
        </p>
        <ul class="details-modal-content_list">
          <li class="modal-content-list_items">
            Cost-effective access to US FDA templates, and rules-based automation for quickly
            creating the set of FDA documentation.
          </li>
          <li class="modal-content-list_items">
            Access to the required FDA documentation template for components like reagents,
            microarray, custom oligos, instruments, software etc. in your Lab Developed Assay Test.
          </li>
          <li class="modal-content-list_items">
            Access to application support personnel to guide you through your entire process.
          </li>
        </ul>
        <p class="details-modal-content_description">
          <strong> Migration Consultation, Guidance, and Information </strong> from our in-house
          regulatory experts to walk you through the strategy and know-how needed for meeting the
          requirements for intended purpose based on US FDA LDT Phaseout Guidance Policy.
        </p>
        <ol style="list-style-type: decimal" class="details-modal-content_list">
          <li class="modal-content-list_items">
            <strong>Stage: 1</strong> beginning 1 year after the publication date of this final
            rule, FDA will expect compliance with MDR requirements, correction and removal reporting
            requirements, and QS requirements under § 820.198 (21 CFR 820.198) (complaint files);
          </li>
          <li class="modal-content-list_items">
            <strong>Stage 2:</strong> beginning 2 years after the publication date of this final
            rule, FDA will expect compliance with requirements not covered during other stages of
            the phaseout policy, including registration and listing requirements, labeling
            requirements, and investigational use requirements;
          </li>

          <li class="modal-content-list_items">
            <strong>Stage 3:</strong> beginning 3 years after the publication date of this final
            rule, FDA will expect compliance with QS requirements under part 820 (21 CFR part 820)
            (other than requirements under § 820.198 (complaint files), which are already addressed
            in stage 1);
          </li>

          <li class="modal-content-list_items">
            <strong>Stage 4:</strong> beginning 3½ years after the publication date of this final
            rule, FDA will expect compliance with premarket review requirements for high-risk IVDs
            offered as LDTs (IVDs that may be classified into class III or that are subject to
            licensure under section 351 of the Public Health Service Act), unless a premarket
            submission has been received by the beginning of this stage in which case FDA intends to
            continue to exercise enforcement discretion for the pendency of its review; and
          </li>

          <li class="modal-content-list_items">
            <strong>Stage 5:</strong> beginning 4 years after the publication date of this final
            rule, FDA will expect compliance with premarket review requirements for moderate-risk
            and low-risk IVDs offered as LDTs (that require premarket submissions), unless a
            premarket submission has been received by the beginning of this stage in which case FDA
            intends to continue to exercise enforcement discretion for the pendency of its review;
          </li>
        </ol>
        <p class="details-modal-content_description text-start">
          <strong
            >Regulatory Publishing and Submission Consultation, Guidance, and Information</strong
          >
          from our in-house regulatory experts to walk you through the strategy and know-how needed
          for meeting the requirements for intended purpose based on product classification as per
          US FDA regulations
        </p>
        <ul class="details-modal-content_list">
          <li class="modal-content-list_items">Guidance on US FDA Product Classification</li>
          <li class="modal-content-list_items">Regulatory Strategy for your US FDA LDT product</li>
          <li class="modal-content-list_items">How to conduct Risk Analysis as per ISO 14971</li>
          <li class="modal-content-list_items">General Safety and Performance Requirements</li>
          <li class="modal-content-list_items">510(k) or PMA Technical Documentation</li>
          <li class="modal-content-list_items">Establishment and Product Registration</li>
        </ul>
        <p class="details-modal-content_description text-start">
          <strong>Benefits for labs</strong>
        </p>
        <ul class="details-modal-content_list">
          <li class="modal-content-list_items">Reduced implementation cost</li>
          <li class="modal-content-list_items">Accelerated implementation</li>
          <li class="modal-content-list_items">Faster revenue recognition</li>
          <li class="modal-content-list_items">Comply to safety and performance Requirements</li>
          <li class="modal-content-list_items">
            Access to the required IVDR documentation for components
          </li>
          <li class="modal-content-list_items">Get customized support and guidance</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->

<!-- OPEN MODAL -->
<div [hidden]="!isOpenSecondModal" class="details-modal-overlay" (click)="closeModal(2)">
  <div (click)="$event.stopPropagation()" class="details-modal">
    <div (click)="closeModal(2)" class="details-modal-close">
      <img class="z-1" src="assets/icons/icon-x-close.svg" alt="" />
    </div>
    <div class="details-modal-header">
      <h1 class="details-modal-header_name">For EU LDT Labs</h1>
      <span class="details-modal-header_title">Get Started with IRIS AI Agent</span>
    </div>
    <div class="details-modal-content">
      <!--      <div class="details-modal-content_img"-->
      <!--           [style.backgroundImage]="'url(assets/imgs/our_expertise.jpg)'"></div>-->
      <div class="">
        <p class="details-modal-content_description">
          Our unique program allows your lab to be compliant with EU IVDR 2017/746 regulation for
          your in-house tests. EU LDT Labs Program is a program that navigates labs to full
          compliance for EU IVDR requirements for Lab Developed Assay.
        </p>
        <ul class="details-modal-content_list">
          <li class="modal-content-list_items">
            Cost-effective access to US IVDR templates, and rules-based automation for quickly
            creating the set of EU IVDR documentation.
          </li>
          <li class="modal-content-list_items">
            Access to the required IVDR documentation template for components like reagents,
            microarray, custom oligos, instruments, software etc. in your Lab Developed Assay Test.
          </li>
          <li class="modal-content-list_items">
            Access to the required FDA documentation template for components like reagents,
            microarray, custom oligos, instruments, software etc. in your Lab Developed Assay Test
          </li>
          <li class="modal-content-list_items">
            Access to application support personnel to guide you through your entire process.
          </li>
        </ul>
        <p class="details-modal-content_description">
          <strong>Migration Consultation, Guidance, and Information</strong> from our in-house
          regulatory experts to walk you through the strategy and know-how needed for meeting the
          requirements for intended purpose based on EU IVDR 2017/746 Article 5(5).
        </p>
        <ol style="list-style-type: decimal" class="details-modal-content_list">
          <li class="modal-content-list_items">
            The laboratory of the health institution is compliant with standard EN ISO 15189 or
            where applicable national provisions
          </li>
          <li class="modal-content-list_items">
            Health institution justification in its documentation that the target patient group's
            specific needs cannot be met, or cannot be met at the appropriate level of performance
            by an equivalent device available on the market
          </li>
          <li class="modal-content-list_items">
            Information that can be requested from health institutions by competent authorities
          </li>
          <li class="modal-content-list_items">
            Declaration of what should be made publicly available
          </li>
          <li class="modal-content-list_items">
            Implementation of Vigilance, incidents, and corrective actions
          </li>
        </ol>
        <p class="details-modal-content_description text-start">
          <strong>Regulatory Publishing and Submission Consultation,</strong>Guidance, and
          Information from our in-house regulatory experts to walk you through the strategy and
          know-how needed for meeting the requirements for intended purpose based on product
          classification as per EU IVDR regulation 2017/746.
        </p>
        <ul class="details-modal-content_list">
          <li class="modal-content-list_items">Guidance on IVDR Product Classification</li>
          <li class="modal-content-list_items">Regulatory Strategy for your IVDR product</li>
          <li class="modal-content-list_items">How to conduct Risk Analysis as per ISO 14971</li>
          <li class="modal-content-list_items">General Safety and Performance Requirements</li>
          <li class="modal-content-list_items">Technical Documentation</li>
          <li class="modal-content-list_items">Declaration of Conformity</li>
        </ul>
        <p class="details-modal-content_description text-start">
          <strong>Benefits for labs</strong>
        </p>
        <ul class="details-modal-content_list">
          <li class="modal-content-list_items">Reduced implementation cost</li>
          <li class="modal-content-list_items">Accelerated implementation</li>
          <li class="modal-content-list_items">Faster revenue recognition</li>
          <li class="modal-content-list_items">Comply to safety and performance Requirements</li>
          <li class="modal-content-list_items">
            Access to the required IVDR documentation for components
          </li>
          <li class="modal-content-list_items">Get customized support and guidance</li>
        </ul>
      </div>
    </div>
  </div>
</div>
