import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import AOS from 'aos';
import { SharedDataService } from '../../shared/services/shared-data.service';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PopupFormComponent } from '../../shared/components/popup-form/popup-form.component';

interface HomeContent {
  header: string;
  description: string;
  list: string[];
  link: string;
  imgPath: string;
}

interface HomeAdvantage {
  icon: string;
  header: string;
  ammount: number;
  subText: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, OnDestroy {
  isOpenFirstModal: boolean = false;
  isOpenSecondModal: boolean = false;
  ngUnsubscribe$ = new Subject<void>();
  test: boolean = true;
  constructor(
    private viewportScroller: ViewportScroller,
    private _cdr: ChangeDetectorRef,
    private _SharedDataService: SharedDataService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    AOS.init();
    AOS.refresh();

    combineLatest([
      this._SharedDataService.isFirstModalOpen,
      this._SharedDataService.isSecondModalOpen
    ])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([isFirstModal, isSecondModal]) => {
        this.isOpenFirstModal = isFirstModal;
        this.isOpenSecondModal = isSecondModal;
        this._cdr.detectChanges();
      });
  }

  Contents: HomeContent[] = [
    {
      header: 'OUR SERVICES',
      description:
        'LDTtoIVD offers expert regulatory, clinical, and quality assurance services which includes',
      list: [
        'Setup a Quality Management System and Procedures for ISO 13485, ISO 15189,  MDSAP, EU MDR 2017/745, EU IVDR 2017/746, CLIA, CAP, NYSDOH',
        'Develop a comprehensive product  regulatory strategy, LDT to IVD assay migration and submissions plan, ensuring compliance with FDA and EU.',
        'Develop Product Post Market Surveillance, Vigilance, Complaints, and Field Actions Process and Procedures'
      ],
      link: '/info/services',
      imgPath: 'our_services.JPG'
    },
    {
      header: 'OUR PRODUCTS',
      description: 'LDTtoIVD sells ready to implement product solutions which includes',
      list: [
        'Quality Manual, Procedures, Work Instructions, and Forms for ISO 13485, ISO 15189, MDSAP, EU MDR, EU IVDR 2017/746, CLIA, CAP, NYSDOH',
        'Procedures, Forms and Process for Regulatory Strategy,  LDT Assay Migration and Submissions, ensuring compliance with EU, FDA including Analytical and Clinical Study',
        'Procedures, Forms and Process for Post Market Surveillance, Vigilance, Complaints, and Field Actions'
      ],
      link: '/info/products',
      imgPath: 'our_products.jpg'
    },
    {
      header: 'OUR CLIENTS',
      description:
        'At LDTtoIVD , we have worked with a number of clients in Medical Device, IVD, LDT, SaMD, and digital health Industries',
      list: [
        'Our clients trust us for our expert regulatory, clinical, and quality assurance consulting services and products.',
        'We believe in delivering high quality and timely services to our clients to ensure their success in the market and assist in innovation and growth.',
        'Our clients range from small startups to large multinational corporations. We have helped our clients achieve their goals and improve patient safety and effectiveness outcomes.'
      ],
      link: '',
      imgPath: 'our_clients.JPG'
    }
  ];

  Advantages: HomeAdvantage[] = [
    {
      icon: 'icon-advantage-law.svg',
      header: 'Regulatory Intelligence',
      ammount: 2.5,
      subText: '(Right Intended Use and Predicate)'
    },

    {
      icon: 'icon-advantage-hospital.svg',
      header: 'Clinical Intelligence',
      ammount: 5,
      subText: '(Optimized Clinical Study Size and Low Cost)'
    },

    {
      icon: 'icon-advantage-group.svg',
      header: 'PMS Intelligence',
      ammount: 10,
      subText: '(No Recalls and Stop Shipments)'
    }
  ];

  scrollToClients(id: string): void {
    this.viewportScroller.scrollToAnchor(id);
  }

  closeModal(index: number): void {
    this._SharedDataService.isFirstModalOpen.next(false);
    this._SharedDataService.isSecondModalOpen.next(false);

    switch (index) {
      case 1:
        this.isOpenFirstModal = false;
        break;
      case 2:
        this.isOpenSecondModal = false;
        break;
    }
  }

  openPopUpForm() {
    this._SharedDataService.openModalForm(this.viewContainerRef);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
