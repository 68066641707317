<div class="about-banner">
  <h1 class="about-banner_header text-center">ABOUT LDTtoIVD</h1>
</div>
<div class="about-wrapper">
  <div class="container">
    <div class="about-content">
      <div class="d-flex flex-gap-48px flex-wrap">
        <p class="about-content_text">
          LDTtoIVD a business division of Antrix offers a full range of expert regulatory affairs,
          clinical affairs and quality assurance consulting services and sells product to the
          Medical Device, IVD, LDT, Life Sciences, Pharmaceutical, and Biotech Industries worldwide
          powered by AI, ML, and NLP.
          <br />
          <br />
          We specialize in medical devices in the area of chemistry, immunology, hematology,
          microbiology, pathology, cardiovascular, ENT, Ophthalmic, Radiology and so on. Antrix also
          has experts in the area of Cosmetics and Food Safety manufacturing facility setup.
          <br />
          <br />
          Our main objective is to help our clients navigate through the complex regulatory systems
          and achieve successful product development, approval, and commercialization in US FDA, EU,
          Health Canada, Australia TGA, Brazil, China and ROW. We specialize in setting up ISO
          13485, ISO 9001, ISO 15189, ISO 27001, ISO 14971, ISO 62366, ISO 62304, ISO 60601, ISO
          61010, MDSAP, EU MDR 2017/745, EU IVDR 2017/746, ISO 22716, ISO 22000, CLIA, and CAP
          Quality Management Systems and Certification. We assist in product innovation and
          increasing the revenue.
          <br />
          <br />
          Our team of industry experts has years of experience and a proven track record in
          delivering high-quality services to our clients.
        </p>
        <img class="about-content_img" src="assets/imgs/About-discuss.png" alt="" />
      </div>
    </div>

    <div class="team-section">
      <h1 class="text-center mb-16px about-banner_header">MEET THE TEAM</h1>
      <p class="text-center w-50 team-section_text">
        Our ISO.AI and LDTtoIVD.com team consists of young and veteran industry leaders
      </p>
      <div class="team-section_wrapper">
        <div class="row">
          <div
            *ngFor="let item of teamAntrix; let i = index"
            class="col-lg-4 col-md-6 mb-48px pl-16px pr-16px"
          >
            <div class="team-member">
              <img class="team-member_img" src="assets/imgs/member/{{ item.imgPath }}" alt="" />
              <div class="team-member_info">
                <h3 class="team-member_info-name">{{ item.name }}</h3>
                <p class="team-member_info-title">
                  {{ item.title }}
                </p>
                <p class="team-member_info-description">
                  {{ item.description }}
                </p>
              </div>
              <button (click)="openModal(i, title, content)" class="team-member_btn text-start">
                Learn more >
              </button>
              <ng-template #title>
                <div class="details-modal-header">
                  <h1 class="details-modal-header_name">{{ item.name }}</h1>
                  <p class="details modal-header_title">{{ item.title }}</p>
                </div>
              </ng-template>
              <ng-template #content>
                <div class="details-modal-content">
                  <img
                    class="details-modal-content_img"
                    src="assets/imgs/member/{{ item.imgPath }}"
                    alt=""
                  />
                  <p class="details-modal-content_description">
                    {{ item.description }}
                  </p>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="team-section">
      <h1 class="text-center mb-16px about-banner_header">OUR ANTRIX TEAM</h1>
      <p class="text-center w-50 team-section_text">
        Our team consists of highly skilled and experienced professionals who are dedicated to
        providing the best possible services to our clients.
      </p>
      <div class="team-section_wrapper">
        <div class="row">
          <div
            *ngFor="let item of data; let i = index"
            class="col-lg-4 col-md-6 mb-48px pl-16px pr-16px"
          >
            <div class="team-member">
              <img class="team-member_img" src="assets/imgs/member/{{ item.imgPath }}" alt="" />
              <div class="team-member_info">
                <h3 class="team-member_info-name">{{ item.name }}</h3>
                <p class="team-member_info-title">
                  {{ item.title }}
                </p>
                <p class="team-member_info-description">
                  {{ item.description }}
                </p>
              </div>
              <button (click)="openModal(i, title, content)" class="team-member_btn text-start">
                Learn more >
              </button>
              <ng-template #title>
                <div class="details-modal-header">
                  <h1 class="details-modal-header_name">{{ item.name }}</h1>
                  <p class="details modal-header_title">{{ item.title }}</p>
                </div>
              </ng-template>
              <ng-template #content>
                <div class="details-modal-content">
                  <img
                    class="details-modal-content_img"
                    src="assets/imgs/member/{{ item.imgPath }}"
                    alt=""
                  />
                  <p class="details-modal-content_description">
                    {{ item.description }}
                  </p>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="about-banner-2">
  <h1 class="about-banner-2_header text-center">Expert RA/QA/CA Advisors</h1>
  <p class="about-banner-2_text text-center">
    Antrix has several RA/QA/CA Expert Consulting Advisors who work on variety of projects. Some of
    the projects they execute are ISO 13485, FDA 820.30, MDSAP, EU IVDR, EU MDR, EU LDT, FDA LDT,
    ISO 15189, ISO 22000, ISO 22716, Title 21 CFR Part 210, 211 QMS Implementation. We have
    dedicated consultants to assist the LDT customers migrate their product to regulated IVD
    products. Consulting advisors also assist in regulatory pathways and strategy for Medical Device
    Design and Process Verification and Validation, Complaint Handling, ISO and FDA Audits etc.
  </p>
</div>
