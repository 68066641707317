<div class="row">
  <h1 class="not-found-text" *ngIf="ProductsData.length < 1">Item Not Found</h1>
  <div
    *ngFor="let data of ProductsData; let i = index"
    class="col-lg-4 col-md-6 pr-16px pl-16px mb-45px"
  >
    <div class="card">
      <div class="card-img-top" [style.backgroundImage]="'url(assets/imgs/'+ data.imgPath+')'">

      </div>
      <div class="card-body">
        <h5 class="card-title m-0">{{ data.Name }}</h5>
        <div>
          <span class="card-subTitle mr-12px">Part No:</span>
          <span class="card-subTitle fw-medium">{{ data.PartNumber }}</span>
        </div>

        <p class="card-text m-0">
          {{ data.Description }}
        </p>
        <div class="d-flex flex-gap-24px">
          <button
            (click)="
              onClick('contact', data.Name, data.PartNumber)
            "
            class="first-btn"
          >
            {{ FirstBtnText }}
          </button>
          <button (click)="showModal(i)" class="second-btn">Learn more</button>
        </div>
        <p class="card-subText">
          {{ SubText }}
        </p>
        <!-- OPEN MODAL -->
        <div
          [hidden]="activeItemIndex !== i"
          class="details-modal-overlay"
          (click)="closeModal()"
        >
          <div (click)="$event.stopPropagation()" class="details-modal">
            <div (click)="closeModal()" class="details-modal-close">
              <img class="z-1" src="assets/icons/icon-x-close.svg" alt=""/>
            </div>
            <div class="details-modal-header">
              <h1 class="details-modal-header_name">{{ data.Name }}</h1>
              <span class="card-subTitle mr-12px">Part No:</span>
              <span class="card-subTitle fw-bold">{{ data.PartNumber }}</span>
            </div>
            <div class="details-modal-content">
              <div class="d-flex">
                <img
                  class="details-modal-content_img"
                  src="assets/imgs/{{ data.imgPath }}"
                  alt=""
                />
              </div>
              <div class="">
                <p
                  class="details-modal-content_description mb-12px"
                  [innerHTML]="data.Header"
                ></p>
                <ul class="details-modal-content_list">
                  <li
                    class="modal-content-list_items"
                    *ngFor="let item of data.List"
                    [innerHTML]="item"
                  ></li>
                </ul>
                <p
                  class="details-modal-content_description"
                  [innerHTML]="data.Footer"
                ></p>
              </div>

              <button
                (click)="
                  onClick('contact', data.Name, data.PartNumber)
                "
                class="details-modal-content_btn"
              >
                {{ FirstBtnText }}
              </button>
              <p class="card-subText text-center">
                {{ SubText }}
              </p>
            </div>
          </div>
        </div>
        <!-- END MODAL -->
      </div>
    </div>
  </div>
</div>

