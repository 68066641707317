import {Component, Input, OnInit} from '@angular/core';
import {END_POINT} from "../../const/end-point.const";

@Component({
  selector: 'app-youtube-iframe',
  templateUrl: './youtube-iframe.component.html',
  styleUrls: ['./youtube-iframe.component.scss']
})
export class YoutubeIframeComponent {
  @Input() YT_URL: string;
}
