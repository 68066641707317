import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

import {AntDesignModule} from './ant-design/ant-design.module';
import {ProductServiceLayoutRoutingModule} from '../components/product-service-layout/product-service-routing.module';
import {SafeUrlPipe} from './pipe/safe-url.pipe';
import {YoutubeIframeComponent} from './components/youtube-iframe/youtube-iframe.component';

@NgModule({
  declarations: [
    SafeUrlPipe,
    YoutubeIframeComponent
  ],
  imports: [AntDesignModule, ProductServiceLayoutRoutingModule],
  exports: [AntDesignModule, SafeUrlPipe, YoutubeIframeComponent],
})
export class SharedModule {
}
